<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">仓库管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入仓库名称" class="iw" ></el-input>
                <el-input v-model="code" placeholder="请输入仓库代码" class="iw" ></el-input>
                <!-- <el-select v-model="type" placeholder="仓库类型"  filterable  clearable class="iw">
                        <el-option v-for="item in typeList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select> -->
                <el-select v-model="status" placeholder="状态"  filterable  clearable class="iw">
                        <el-option label="可用" value="0" ></el-option>
                        <el-option label="不可用" value="1" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="仓库名称"></el-table-column>
                    <el-table-column prop="Code" label="仓库代码"></el-table-column>
                    <!-- <el-table-column prop="Type" label="仓库类型"></el-table-column> -->
                    <el-table-column prop="Status" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.Status==0" style="color:#67C23A">可用</span>
                            <span v-else style="color:#F56C6C">不可用/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Index" label="排序"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="仓库名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="仓库代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="仓库类型" prop="typeSnowID">
                    <el-select v-model="postInfo.typeSnowID" placeholder="请选择仓库类型" filterable clearable style="width:100%;">
                        <el-option v-for="item in typeList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="状态">
                    <el-radio v-model="postInfo.status" label="0">可用</el-radio>
                    <el-radio v-model="postInfo.status" label="1">不可用</el-radio>
                </el-form-item>
                <el-form-item label="排序" prop="index">
                    <el-input v-model="postInfo.index" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>

export default {
  name: "UnitManage",

  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入仓库名称"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入仓库代码"));
      } else {
        callback();
      }
    };
    // var typeSnowID = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请选择仓库类型"));
    //   } else {
    //     callback();
    //   }
    // };
    var index= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入排序'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('排序只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };
    
    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        code:"",
        type:"",
        status:"",


        typeList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            // typeSnowID: [{ validator: typeSnowID, trigger: "blur" }],
            index: [{ validator: index, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    // this.getTypeList()
    this.getPostList()
  },
  methods: {
    getTypeList(){
        this.$storeAxios.post("WareHouseType/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.typeList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            code:this.code,
            typeSnowID:this.type,
            status:this.status,
        }
        this.$storeAxios.post("WareHouse/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            name:"",
            code:"",
            typeSnowID:"",
            index:"",
            status:"0",
        }
        this.doTypeStr="添加仓库"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("WareHouse/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            code:item.Code,
            typeSnowID:item.TypeSnowid,
            index:item.Index,
            status:item.Status.toString(),
        }
        this.doTypeStr="修改仓库"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("WareHouse/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除仓库：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("WareHouse/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>