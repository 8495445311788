<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">出入库查询</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="status" placeholder="选择类型"  filterable  class="iwu" @change="searchPost">
                        <el-option label="入库" value="1" ></el-option>
                        <el-option label="调拨" value="3" ></el-option>
                        <el-option label="出库" value="4" ></el-option>
                </el-select>
                <el-select v-model="goodsid" placeholder="选择物料"  filterable  clearable class="iwu">
                        <el-option v-for="item in goodsList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" class="iwss"></el-date-picker>

                <el-select v-model="ckid" placeholder="选择仓库"  filterable  clearable class="iw" @change="storeChange">
                        <el-option v-for="item in storeList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="hjid" placeholder="选择货架"  filterable  clearable class="iw"  @change="rackChange">
                        <el-option v-for="item in rackList" :key="item.SnowID" :label="item.Code" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="rackBox.h.value" placeholder="选择行号"  filterable  clearable class="iw"  v-if="rackBox.h.max>0"  @change="rowChange">
                        <el-option v-for="index of rackBox.h.max" :key="index" :label="index" :value="index" ></el-option>
                </el-select>
                <el-select v-model="rackBox.l.value" placeholder="选择列号"  filterable  clearable class="iw"  v-if="rackBox.l.max>0"  @change="colChange">
                        <el-option v-for="index of rackBox.l.max" :key="index" :label="index" :value="index" ></el-option>
                </el-select>
                <el-select v-model="rackBox.g.value" placeholder="选择货格号"  filterable  clearable class="iw"  v-if="rackBox.g.max>0">
                        <el-option v-for="index of rackBox.g.max" :key="index" :label="index" :value="index" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                    <el-table-column prop="GoodName" label="货名"></el-table-column>
                    <el-table-column prop="GoodCount" label="入库数量" v-if="status=='1'"></el-table-column>
                    <el-table-column prop="ONEMONEY" label="入库单价" v-if="status=='1'"></el-table-column>
                    <el-table-column prop="SAVECODE" label="入库货格" v-if="status=='1'"></el-table-column>
                    <el-table-column prop="CreateTime" label="入库时间" v-if="status=='1'" width="165"></el-table-column>
                    <el-table-column prop="SOURCE" label="货物来源" v-if="status=='1'"></el-table-column>

                 
                    <el-table-column prop="OLDSAVECODE" label="被调货格" v-if="status=='3'"></el-table-column>
                    <el-table-column prop="SAVECODE" label="调入货格" v-if="status=='3'"></el-table-column>
                    <el-table-column prop="CreateTime" label="调拨时间" v-if="status=='3'"></el-table-column>
                    <el-table-column prop="GoodCount" label="调入数量" v-if="status=='3'"></el-table-column>

                    <el-table-column prop="GoodCount" label="出库数量" v-if="status=='4'"></el-table-column>
                    <el-table-column prop="MONEY" label="出库金额" v-if="status=='4'"></el-table-column>
                    <el-table-column prop="SAVECODE" label="出库货格" v-if="status=='4'"></el-table-column>
                    <el-table-column prop="Target" label="出库去向" v-if="status=='4'"></el-table-column>
                    <el-table-column prop="CreateTime" label="出库时间" v-if="status=='4'"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: "UnitManage",

  data() {

    
    return {
       
        canDo:true,
        showPagination:false,
        
        status:"1",
        goodsid:"",
        goodsList:[],
        times:[],

        storeList:[],
        ckid:"",
        rackList:[],
        hjid:"",
        rackBox:{
            rack:null,
            h:{max:0,value:"",},
            l:{max:0,value:"",},
            g:{max:0,value:"",},
			boxCode:"",
        },
        
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getGoodsList()
    this.getStoreList()
  },
  methods: {

    getGoodsList(){
        this.$storeAxios.post("Good/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.goodsList=response.data.DATA
                } 
            })
    },
    getStoreList(){
        this.$storeAxios.post("WareHouse/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.storeList=response.data.DATA
                    if(this.storeList.length>0){
                        if(this.ckid!=""){
                            this.getRackList(this.ckid)
                        }else{
                            this.getRackList(this.storeList[0].SnowID)
                        }
                    }
                } 
            })
    },
    storeChange(e){
        this.rackList=[]
        this.hjid=[]
        this.rackBox={
            rack:null,
            h:{max:0,value:"",},
            l:{max:0,value:"",},
            g:{max:0,value:"",},
			boxCode:"",
        }
        if(e!=''){
            this.getRackList(e)
        }
    },
    getRackList(storeid){
        if(storeid!=""){
            this.$storeAxios.post("Shelving/GetDataList", {wareHouseSnowID:storeid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.rackList=response.data.DATA
                } 
            })
        }  
    },
    rackChange(e){
        this.rackBox={
            rack:null,
            h:{max:0,value:"",},
            l:{max:0,value:"",},
            g:{max:0,value:"",},
			boxCode:"",
        }
        if(e!=""){
            for(var i=0;i<this.rackList.length;i++){
                if(this.rackList[i].SnowID ==e){
                    this.rackBox.rack=JSON.parse(this.rackList[i].Info)
                    this.rackBox.h.max=this.rackBox.rack.length
                    break;
                }
            }
        }
    },
    rowChange(){
        this.rackBox.l={max:0,value:"",}
        this.rackBox.g={max:0,value:"",}
        if( this.rackBox.h.value!=""){
            var v=parseInt(this.rackBox.h.value)-1
            var ll=this.rackBox.rack[v]
            this.rackBox.l.max=ll.length
        }
    },
    colChange(){
        this.rackBox.g={max:0,value:"",}
        if( this.rackBox.h.value!="" && this.rackBox.l.value!=""){
            var h=parseInt(this.rackBox.h.value)-1
            var l=parseInt(this.rackBox.l.value)-1
            var g=this.rackBox.rack[h][l]
            this.rackBox.g.max=g
        }
    },

    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.times && this.times.length==2){
            st=this.comjs.getDateStrByDate(this.times[0])
            et=this.comjs.getDateStrByDate(this.times[1])
        }
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            goodSnowID:this.goodsid,
            wareHouseSnowID:this.ckid,
            shelvingSnowID:this.hjid,
            status:this.status,
            startTime:st,
            endTime:et,
            rowIndex:this.rackBox.h.value.toString(),
            columnIndex:this.rackBox.l.value.toString(),
            gridIndex:this.rackBox.g.value.toString(),
        }
        this.$storeAxios.post("GoodIn/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>