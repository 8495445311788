<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">表单管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="输入名称查询" class="iw" ></el-input>
                <el-input v-model="alias" placeholder="输入别名查询" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="moreRow">
                                    <div class="moreRowTit">字段列表</div>
                                    <el-table :data="props.row.FORMITEMMODELS"  class="moreRowTable">
                                        <el-table-column prop="FIELDNAME" label="名称"></el-table-column>
                                        <el-table-column prop="FIELDTYPENAME" label="类型"></el-table-column>
                                        <el-table-column prop="FIELDENNAME" label="英文名称"></el-table-column>
                                        
                                        <!-- <el-table-column prop="ISCANMUTI" label="可以多选">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.ISCANMUTI=='1'" style="color:#67C23A">可以</span>
                                                <span v-else-if="scope.row.ISCANMUTI=='0'" style="color:#F56C6C">不可以</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="ISCANNULL" label="可以为空">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.ISCANNULL=='1'" style="color:#67C23A">可以</span>
                                                <span v-else-if="scope.row.ISCANNULL=='0'" style="color:#F56C6C">不可以</span>
                                            </template>
                                        </el-table-column> -->
                                        <el-table-column prop="DATAFROMJSON" label="数据来源"></el-table-column>
                                    </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ALIAS" label="别名"></el-table-column>
                    <el-table-column prop="ISUPATTACHMENT" label="是否需要上传附件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISUPATTACHMENT==1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DESCRIBE" label="说明"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showFormLayout(scope.row)"  type="text" size="small" style="color:#409EFF">布局</el-button>
                            
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="别名" prop="ALIAS">
                    <el-input v-model="postInfo.ALIAS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="必须上传附件">
                    <el-radio v-model="postInfo.ISUPATTACHMENT" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISUPATTACHMENT" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="说明" prop="DESCRIBE">
                    <el-input v-model="postInfo.DESCRIBE" autocomplete="off"></el-input>
                </el-form-item>
                 <el-form-item label="字段" prop="FORMITEMMODELS">
                     <span class="formItemMsg">(每行最大宽度为1200。合并单元格需要相同行号和列号且定相同的字段类型)</span>
                      <el-button type="text" @click="addFieldTtem()" class="addFieldTtem">添加字段</el-button>
                      <div class="itemBox"  v-for="(field, index) in postInfo.FORMITEMMODELS" :key="field.key">
                            <div class="itemOut">
                                <div class="itemTit">
                                    <p>字段{{index+1}}</p>    
                                    <el-button type="text" style="color:#F56C6C;float:right;"  @click="delFieldItem(index)">删除</el-button>
                                </div>  
                                <!-- <el-form-item label="行号" :prop="'FORMITEMMODELS.' + index + '.ROWINDEX'" :rules="{validator:hhIndex,  trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.ROWINDEX" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="列号" :prop="'FORMITEMMODELS.' + index + '.COLUMNINDEX'" :rules="{validator:lhIndex,  trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.COLUMNINDEX" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="名称宽度" :prop="'FORMITEMMODELS.' + index + '.LABELWIDTH'" :rules="{ validator:mcWidth,  trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.LABELWIDTH" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="值宽度" :prop="'FORMITEMMODELS.' + index + '.VALUEWIDTH'" :rules="{ validator:zWidth,  trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.VALUEWIDTH" autocomplete="off"></el-input>
                                </el-form-item> -->
                                <el-form-item label="名称" :prop="'FORMITEMMODELS.' + index + '.FIELDNAME'" :rules="{ required: true, message: '字段名称不能为空', trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.FIELDNAME" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="类型" class="halfItem">
                                    <el-select  v-model="field.FIELDTYPE" placeholder="点击选择类型" style="width:100%">
                                            <el-option v-for="item in fieldTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="英文名称" :prop="'FORMITEMMODELS.' + index + '.FIELDENNAME'" :rules="{ required: true, message: '英文名称不能为空', trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="field.FIELDENNAME" autocomplete="off"></el-input>
                                </el-form-item>
                                 <el-form-item label="数据来源" class="halfItem rfbox">
                                    <!-- <el-input  v-model="field.DATAFROMJSON" autocomplete="off" placeholder="下拉框、单选按钮、复选框（key,value数据集合）"></el-input> -->
                                    <el-select  v-model="field.DATAFROMJSON" placeholder="点击选择数据来源" style="width:100%">
                                            <el-option label="用户" value="用户"></el-option>
                                            <el-option label="风场" value="风场"></el-option>
                                            <el-option label="仓库" value="仓库"></el-option>
                                            <el-option label="车辆" value="车辆"></el-option>
                                            <el-option label="资料库" value="资料库"></el-option>
                                            <el-option label="定检任务" value="定检任务"></el-option>
                                            <el-option label="备品备件" value="备品备件"></el-option>
                                            <el-option v-for="zd in zdxshly" :label="zd.NAME" :value="zd.SNOWID" :key="zd.SNOWID"></el-option>
                                    </el-select>
                                    <!-- <span class="optionsModelBtn" @click="showOptionsModal">模板工具</span> -->
                                </el-form-item>
                                <!-- <el-form-item label="可否多选" class="halfItem">
                                    <el-select  v-model="field.ISCANMUTI" placeholder="点击选择可否多选" style="width:100%">
                                            <el-option  label="否" value="0"></el-option>
                                            <el-option  label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="可否为空" class="halfItem">
                                    <el-select  v-model="field.ISCANNULL" placeholder="点击选择可否为空"  style="width:100%">
                                            <el-option  label="否" value="0"></el-option>
                                            <el-option  label="是" value="1"></el-option>
                                    </el-select>
                                </el-form-item> -->
        
                            </div>
                        </div>      
                </el-form-item>   

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="数据来源模板" :visible.sync="sjlyModal" width="800px" >
            <OPTIONS></OPTIONS>
        </el-dialog>
    </div>
</template>
<script>
import OPTIONS from "../../components/OPTIONS"
export default {
  name: "WFFieldType",
  components:{
    OPTIONS,//数据来源模板
  },
  data() {

    var NAME =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入名称'));
        }else {
          callback();
        }
    };

    var ALIAS =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入别名'));
        }else {
          callback();
        }
    };

    var DESCRIBE =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入说明'));
        }else {
          callback();
        }
    };

    var FORMITEMMODELS=(rule, value, callback) => {
        if (value.length==0) {
          callback(new Error('请添加字段'));
        }else {
          callback();
        }
    };

    return {
        canDo:true,
        
        name:"",
        alias:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        fieldTypeList:[],

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            ALIAS:[{ validator: ALIAS, trigger: "blur" }],
            DESCRIBE:[{ validator: DESCRIBE, trigger: "blur" }],
            FORMITEMMODELS:[{ validator: FORMITEMMODELS, trigger: "blur" }],
        },

        sjlyModal:false,
        showFormModal:false,
        zdxshly:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getFieldList()
    this.getPostList()
    this.getDicForForm()
  },
  methods: {
    hhIndex(rule, value, callback) {
        //var f=parseInt(rule.field.split(".")[1])
        if (value === '') {
          callback(new Error('请输入行号'));
        }else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('行号只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    },
    getFieldList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:20,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("FieldType/GetFieldTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fieldTypeList=response.data.DATA
                } 
            })
    },
    getDicForForm(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Dict/GetDictTreeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        if(list[i].NAME=='表单数据来源字典表' || list[i].TYPE=='表单数据来源字典表'){
                            if(list[i].CHILD){
                                this.zdxshly=list[i].CHILD
                            }else{
                                this.zdxshly=[]
                            }
                            
                        }
                    }
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            ALIAS:this.alias,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Form/GetFormList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            ALIAS:"",
            DESCRIBE:"",
            ISUPATTACHMENT:"0",
            FORMITEMMODELS:[]
        }
        this.doTypeStr="添加表单"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Form/CreateNewForm", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除此表单？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Form/DeleteForm", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    //console.log(this.postInfo)
                    this.postInfo.ISUPATTACHMENT = parseInt(this.postInfo.ISUPATTACHMENT)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    addFieldTtem(){
        var l=this.postInfo.FORMITEMMODELS.length
        var item={
            FIELDNAME:"",
            FIELDTYPE:this.fieldTypeList[0].SNOWID,
            ISCANMUTI:"0",
            ISCANNULL:"0",
            LABELWIDTH:0,
            VALUEWIDTH:0,
            ROWINDEX:l,
            COLUMNINDEX:0,
        }
        this.postInfo.FORMITEMMODELS.push(item)
    },
    delFieldItem(index){
        var nl=[]
        for(var i=0;i< this.postInfo.FORMITEMMODELS.length;i++){
            if(i!=index){
                nl.push(this.postInfo.FORMITEMMODELS[i])
            }
        }
        this.postInfo.FORMITEMMODELS =nl
    },
    showOptionsModal(){
        this.sjlyModal=true
    },
    showFormLayout(item){
        window.open("/statics/pages/BDBJSZ.html?fid="+item.SNOWID+"&token="+this.$store.state.token)
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.addFieldTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

.fullItem{width: 100%;float:left;margin-bottom: 25px!important;}
.rfbox{position: relative;}
.optionsModelBtn{cursor: pointer;position: absolute;font-size: 14px;right: 10px;top: 0px;color: #409EFF;}
</style>