import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import DictionaryManage from '../views/XTSZ/DictionaryManage.vue'
import SBGZGL from '../views/XTSZ/SBGZGL.vue'
import JHGL from '../views/XTSZ/JHGL.vue'
import SPJKGL from '../views/XTSZ/SPJKGL.vue'
import ProvisionalPlan from '../views/XTSZ/ProvisionalPlan.vue'
import CreatorManage from '../views/XTSZ/CreatorManage.vue'
import projectDataFile from '../views/XTSZ/projectDataFile.vue'

import PostManage from '../views/YHGL/PostManage.vue'
import JobManage from '../views/YHGL/JobManage.vue'
import UserManage from '../views/YHGL/UserManage.vue'
import UserPass from '../views/YHGL/UserPass.vue'
import LocatorManage from '../views/YHGL/LocatorManage.vue'
import UserWorkLog from '../views/YHGL/UserWorkLog.vue'
import WorkTimeCount from '../views/YHGL/WorkTimeCount.vue'



import windFieldManage from '../views/FCGL/windFieldManage.vue'
import windFieldMachineType from '../views/FCGL/windFieldMachineType.vue'
import NoticeOfWork from '../views/FCGL/NoticeOfWork.vue'
import FDJHSZ from '../views/FCGL/FDJHSZ.vue'
import ElectricPowerMachine from '../views/FCGL/ElectricPowerMachine.vue'
import MachineRunManage from '../views/FCGL/MachineRunManage.vue'
import YearCostPlan from '../views/FCGL/YearCostPlan.vue'
import ExpensesManage from '../views/FCGL/ExpensesManage.vue'
import SYZFJ from '../views/FCGL/SYZFJ.vue'
import FCLX from '../views/FCGL/FCLX.vue'



import TJGL from '../views/PXKS/TJGL.vue'
import SJGL from '../views/PXKS/SJGL.vue'
import PXGL from '../views/PXKS/PXGL.vue'
import KSGL from '../views/PXKS/KSGL.vue'
import WDKS from '../views/PXKS/WDKS.vue'
import KSLB from '../views/PXKS/KSLB.vue'
import KSA from '../views/PXKS/KSA.vue'
import KSB from '../views/PXKS/KSB.vue'
import KSC from '../views/PXKS/KSC.vue'
import KSD from '../views/PXKS/KSD.vue'

import XJGL from '../views/SBXJ/XJGL.vue'
import CheckTempletItem from '../views/SBXJ/CheckTempletItem.vue'
import CheckTemplet from '../views/SBXJ/CheckTemplet.vue'
import CheckTaskManage from '../views/SBXJ/CheckTaskManage.vue'
import TaskAllot from '../views/SBXJ/TaskAllot.vue'
import CheckResultManage from '../views/SBXJ/CheckResultManage.vue'
import RWCX from '../views/SBXJ/RWCX.vue'
import RWLCCX from "../views/SBXJ/RWLCCX.vue"
import MKGL from "../views/SBXJ/MKGL.vue"

import DJRWCK from '../views/DJGL/DJRWCK.vue'
import NDDJJH from '../views/DJGL/NDDJJH.vue'
import RCDJJH from '../views/DJGL/RCDJJH.vue'
import ZL from '../views/DJGL/ZL.vue'
import InspectionTemplate from '../views/DJGL/InspectionTemplate.vue'
import WorkSplit from '../views/DJGL/WorkSplit.vue'


import ZLKITEM from '../views/ZLK/ZLKItem.vue'
import ZLKFL from '../views/ZLK/ZLKFL.vue'
import ZLKGL from '../views/ZLK/ZLKGL.vue'
import ZLKQXGL from '../views/ZLK/ZLKQXGL.vue'

import HYJL from '../views/DJGL/HYJL.vue'



import DDTD from '../views/DDJCQJC/DDTD.vue'
import TDJH from '../views/DDJCQJC/TDJH.vue'
import NDYS from '../views/DDJCQJC/NDYS.vue'
import CQJ from '../views/DDJCQJC/CQJ.vue'
import YJGL from '../views/DDJCQJC/YJGL.vue'
import LCJCWTJL from '../views/DDJCQJC/LCJCWTJL.vue'
import DBJZXZDJC from '../views/BJJC/DBJZXZDJC.vue'
import YLJCYGH from '../views/BJJC/YLJCYGH.vue'

import DTJC from '../views/JSJD/DTJC.vue'
import JSJDA from '../views/JSJD/JSJDA.vue'
import JSJDB from '../views/JSJD/JSJDB.vue'
import JSJDC from '../views/JSJD/JSJDC.vue'
import JSJDD from '../views/JSJD/JSJDD.vue'
import JSJDE from '../views/JSJD/JSJDE.vue'
import JSJDF from '../views/JSJD/JSJDF.vue'
import JSJDG from '../views/JSJD/JSJDG.vue'
import JSJDH from '../views/JSJD/JSJDH.vue'

import DQSBJCJL from '../views/DQSB/DQSBJCJL.vue'
import YGTXL from '../views/SCKH/YGTXL.vue'
import DZBGZ from '../views/SCKH/DZBGZ.vue'
import NDZJ from '../views/SCKH/NDZJ.vue'
import YGSZBG from '../views/SCKH/YGSZBG.vue'
import GZTYFA from '../views/SCKH/GZTYFA.vue'

import BXLP from '../views/ZTB/BXLP.vue'
import FCWZKC from '../views/ZTB/FCWZKC.vue'
import GHJGMXB from '../views/ZTB/GHJGMXB.vue'
import GSWZKC from '../views/ZTB/GSWZKC.vue'
import QNYSJH from '../views/ZTB/QNYSJH.vue'
import ZTBYW from '../views/ZTB/ZTBYW.vue'
import CKGL from '../views/ZTB/CKGL.vue'
import KCGL from '../views/ZTB/KCGL.vue'
import GYSGL from '../views/ZTB/GYSGL.vue'
import WZHPFL from '../views/ZTB/WZHPFL.vue'
import WXGL from '../views/ZTB/WXGL.vue'
import ContractManage from '../views/ZTB/ContractManage.vue'


import XTPTZH from '../views/HZTZ/XTPTZH.vue'
import KHX from '../views/HZTZ/KHX.vue'
import KHXM from '../views/HZTZ/KHXM.vue'
import KHGL from '../views/HZTZ/KHGL.vue'
import KHLB from '../views/HZTZ/KHLB.vue'
import SCJL from '../views/HZTZ/SCJL.vue'
import GZZJJGZJH from '../views/HZTZ/GZZJJGZJH.vue'
import QSMZQGLTZ from '../views/HZTZ/QSMZQGLTZ.vue'
import YZFA from '../views/HZTZ/YZFA.vue'




import HQAQGL from '../views/AQGL/HQAQGL.vue'
import SCanteen from '../views/AQGL/SCanteen.vue'
import CLGL from '../views/AQGL/CLGL.vue'
import JYBXJL from '../views/AQGL/JYBXJL.vue'
import YZSQSP from '../views/AQGL/YZSQSP.vue'
import FYZCGL from '../views/AQGL/FYZCGL.vue'



import TDZYCQS from '../views/SQS/TDZY.vue'



import WFFieldType from '../views/WF/WFFieldType.vue'
import WFFormManage from '../views/WF/WFFormManage.vue'

import WFSerialNumber from '../views/WF/WFSerialNumber.vue'
import WFOpinoin from '../views/WF/WFOpinoin.vue'
import WFCreateFlowsheet from '../views/WF/WFCreateFlowsheet.vue'
import WFBackLog from '../views/WF/WFBackLog.vue'
import WFFlowsheetLog from '../views/WF/WFFlowsheetLog.vue'
import WFReadLog from '../views/WF/WFReadLog.vue'
import WFFlowsheet from '../views/WF/WFFlowsheets.vue'

import GoodsManage from '../views/STORE/GoodsManage.vue'
import InOutManage from '../views/STORE/InOutManage.vue'
import LightManage from '../views/STORE/LightManage.vue'
import MatterManage from '../views/STORE/MatterManage.vue'
import RackManage from '../views/STORE/RackManage.vue'
import StoreManage from '../views/STORE/StoreManage.vue'
import VideoManage from '../views/STORE/VideoManage.vue'

import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    props:{code:"9999",},
  },
  {
    path: '/TDZYCQS',
    name: 'TDZYCQS',
    component: TDZYCQS,
    props:{code:"9999",},
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/DictionaryManage',
        name: 'DictionaryManage',
        component: DictionaryManage,
        props:{code:"0102",},
      },
      {
        path: '/SBGZGL',
        name: 'SBGZGL',
        component: SBGZGL,
        props:{code:"9999",},
      },
      {
        path: '/JHGL',
        name: 'JHGL',
        component: JHGL,
        props:{code:"9999",},
      },
      {
        path: '/CreatorManage',
        name: 'CreatorManage',
        component: CreatorManage,
        props:{code:"0101",},
      },
      {
        path: '/projectDataFile',
        name: 'projectDataFile',
        component: projectDataFile,
        props:{code:"0106",},
      },
      {
        path: '/SPJKGL',
        name: 'SPJKGL',
        component: SPJKGL,
        props:{code:"0103",},
      },
      {
        path: '/ProvisionalPlan',
        name: 'ProvisionalPlan',
        component: ProvisionalPlan,
        props:{code:"0104",},
      },

      {
        path: '/PostManage',
        name: 'PostManage',
        component: PostManage,
        props:{code:"0201",},
      },
      {
        path: '/FCLX',
        name: 'FCLX',
        component: FCLX,
        props:{code:"0206",},
      },
      {
        path: '/UserWorkLog',
        name: 'UserWorkLog',
        component: UserWorkLog,
        props:{code:"0207",},
      },
      {
        path: '/JobManage',
        name: 'JobManage',
        component: JobManage,
        props:{code:"0202",},
      },
      {
        path: '/UserManage',
        name: 'UserManage',
        component: UserManage,
        props:{code:"0203",},
      },
      {
        path: '/UserPass',
        name: 'UserPass',
        component: UserPass,
        props:{code:"0205",},
      },
      {
        path: '/WorkTimeCount',
        name: 'WorkTimeCount',
        component: WorkTimeCount,
        props:{code:"0208",},
      },
      {
        path: '/LocatorManage',
        name: 'LocatorManage',
        component: LocatorManage,
        props:{code:"0204",},
      },

      {
        path: '/windFieldManage',
        name: 'windFieldManage',
        component: windFieldManage,
        props:{code:"0301",},
      },
      {
        path: '/SYZFJ',
        name: 'SYZFJ',
        component: SYZFJ,
        props:{code:"0308",},
      },
      {
        path: '/windFieldMachineType',
        name: 'windFieldMachineType',
        component: windFieldMachineType,
        props:{code:"0302",},
      },
      {
        path: '/NoticeOfWork',
        name: 'NoticeOfWork',
        component: NoticeOfWork,
        props:{code:"0105",},
      },
      {
        path: '/ExpensesManage',
        name: 'ExpensesManage',
        component: ExpensesManage,
        props:{code:"0307",},
      },
      
      


      {
        path: '/TJGL',
        name: 'TJGL',
        component: TJGL,
        props:{code:"0602",},
      },
      {
        path: '/SJGL',
        name: 'SJGL',
        component: SJGL,
        props:{code:"0601",},
      },
      {
        path: '/PXGL',
        name: 'PXGL',
        component: PXGL,
        props:{code:"0501",},
      },
      {
        path: '/KSGL',
        name: 'KSGL',
        component: KSGL,
        props:{code:"0603",},
      },
      {
        path: '/WDKS',
        name: 'WDKS',
        component: WDKS,
        props:{code:"9999",},
      },
      {
        path: '/KSLB',
        name: 'KSLB',
        component: KSLB,
        props:{code:"9999",},
      },
      {
        path: '/KSA',
        name: 'KSA',
        component: KSA,
        props:{code:"9999",},
      },
      {
        path: '/KSB',
        name: 'KSB',
        component: KSB,
        props:{code:"9999",},
      },
      {
        path: '/KSC',
        name: 'KSC',
        component: KSC,
        props:{code:"9999",},
      },
      {
        path: '/KSD',
        name: 'KSD',
        component: KSD,
        props:{code:"9999",},
      },
      
      

      {
        path: '/XJGL',
        name: 'XJGL',
        component: XJGL,
        props:{code:"0701",},
      },
      {
        path: '/CheckTempletItem',
        name: 'CheckTempletItem',
        component: CheckTempletItem,
        props:{code:"9999",},
      },
      {
        path: '/CheckTemplet',
        name: 'CheckTemplet',
        component: CheckTemplet,
        props:{code:"9999",},
      },
      {
        path: '/CheckTaskManage',
        name: 'CheckTaskManage',
        component: CheckTaskManage,
        props:{code:"9999",},
      },
      {
        path: '/TaskAllot',
        name: 'TaskAllot',
        component: TaskAllot,
        props:{code:"9999",},
      },
      {
        path: '/CheckResultManage',
        name: 'CheckResultManage',
        component: CheckResultManage,
        props:{code:"0702",},
      },
      {
        path: '/RWCX',
        name: 'RWCX',
        component: RWCX,
        props:{code:"0703",},
      },
      {
        path: '/RWLCCX',
        name: 'RWLCCX',
        component: RWLCCX,
        props:{code:"0704",},
      },
      {
        path: '/MKGL',
        name: 'MKGL',
        component: MKGL,
        props:{code:"0705",},
      },
      {
        path: '/NDDJJH',
        name: 'NDDJJH',
        component: NDDJJH,
        props:{code:"0902",},
      },
      {
        path: '/RCDJJH',
        name: 'RCDJJH',
        component: RCDJJH,
        props:{code:"0903",},
      },
      {
        path: '/DJRWCK',
        name: 'DJRWCK',
        component: DJRWCK,
        props:{code:"0901",},
      },
      

      {
        path: '/HYJL',
        name: 'HYJL',
        component: HYJL,
        props:{code:"9999",},
      },
      {
        path: '/ZL',
        name: 'ZL',
        component: ZL,
        props:{code:"0904",},
      },
      {
        path: '/ZLKITEM',
        name: 'ZLKITEM',
        component: ZLKITEM,
        props:{code:"9999",},
      },
      {
        path: '/InspectionTemplate',
        name: 'InspectionTemplate',
        component: InspectionTemplate,
        props:{code:"0905",},
      },
      {
        path: '/WorkSplit',
        name: 'WorkSplit',
        component: WorkSplit,
        props:{code:"0906",},
      },

      

      {
        path: '/FDJHSZ',
        name: 'FDJHSZ',
        component: FDJHSZ,
        props:{code:"0303",},
      },
      {
        path: '/ElectricPowerMachine',
        name: 'ElectricPowerMachine',
        component: ElectricPowerMachine,
        props:{code:"0304",},
      },
      {
        path: '/WFFieldType',
        name: 'WFFieldType',
        component: WFFieldType,
        props:{code:"0801",},
      },
      {
        path: '/WFFormManage',
        name: 'WFFormManage',
        component: WFFormManage,
        props:{code:"0802",},
      },
      {
        path: '/WFFlowsheet',
        name: 'WFFlowsheet',
        component: WFFlowsheet,
        props:{code:"0803",},
      },
      {
        path: '/WFSerialNumber',
        name: 'WFSerialNumber',
        component: WFSerialNumber,
        props:{code:"9999",},
      },
      {
        path: '/WFCreateFlowsheet',
        name: 'WFCreateFlowsheet',
        component: WFCreateFlowsheet,
        props:{code:"9999",},
      },
      {
        path: '/WFBackLog',
        name: 'WFBackLog',
        component: WFBackLog,
        props:{code:"9999",},
      }, 
      {
        path: '/WFFlowsheetLog',
        name: 'WFFlowsheetLog',
        component: WFFlowsheetLog,
        props:{code:"9999",},
      }, 
      {
        path: '/WFReadLog',
        name: 'WFReadLog',
        component: WFReadLog,
        props:{code:"9999",},
      }, 
      {
        path: '/WFOpinoin',
        name: 'WFOpinoin',
        component: WFOpinoin,
        props:{code:"0804",},
      }, 
      {
        path: '/MachineRunManage',
        name: 'MachineRunManage',
        component: MachineRunManage,
        props:{code:"0305",},
      }, 
      {
        path: '/YearCostPlan',
        name: 'YearCostPlan',
        component: YearCostPlan,
        props:{code:"0306",},
      }, 
      

      {
        path: '/TDJH',
        name: 'TDJH',
        component: TDJH,
        props:{code:"9999",},
      },
      {
        path: '/DDTD',
        name: 'DDTD',
        component: DDTD,
        props:{code:"1001",},
      },
      {
        path: '/NDYS',
        name: 'NDYS',
        component: NDYS,
        props:{code:"9999",},
      },
      {
        path: '/CQJ',
        name: 'CQJ',
        component: CQJ,
        props:{code:"1002",},
      },
      {
        path: '/YJGL',
        name: 'YJGL',
        component: YJGL,
        props:{code:"9999",},
      },
      {
        path: '/LCJCWTJL',
        name: 'LCJCWTJL',
        component: LCJCWTJL,
        props:{code:"9999",},
      },
      {
        path: '/DBJZXZDJC',
        name: 'DBJZXZDJC',
        component: DBJZXZDJC,
        props:{code:"9999",},
      },
      {
        path: '/YLJCYGH',
        name: 'YLJCYGH',
        component: YLJCYGH,
        props:{code:"9999",},
      },

      {
        path: '/DTJC',
        name: 'DTJC',
        component: DTJC,
        props:{code:"1101",},
      },
      {
        path: '/JSJDA',
        name: 'JSJDA',
        component: JSJDA,
        props:{code:"1102",},
      },
      {
        path: '/JSJDB',
        name: 'JSJDB',
        component: JSJDB,
        props:{code:"9999",},
      },
      {
        path: '/JSJDC',
        name: 'JSJDC',
        component: JSJDC,
        props:{code:"9999",},
      },
      {
        path: '/JSJDD',
        name: 'JSJDD',
        component: JSJDD,
        props:{code:"9999",},
      },
      {
        path: '/JSJDE',
        name: 'JSJDE',
        component: JSJDE,
        props:{code:"9999",},
      },
      {
        path: '/JSJDF',
        name: 'JSJDF',
        component: JSJDF,
        props:{code:"9999",},
      },
      {
        path: '/JSJDG',
        name: 'JSJDG',
        component: JSJDG,
        props:{code:"9999",},
      },
      {
        path: '/JSJDH',
        name: 'JSJDH',
        component: JSJDH,
        props:{code:"9999",},
      },


      {
        path: '/DQSBJCJL',
        name: 'DQSBJCJL',
        component: DQSBJCJL,
        props:{code:"9999",},
      },
      {
        path: '/YGTXL',
        name: 'YGTXL',
        component: YGTXL,
        props:{code:"01",},
      },
      {
        path: '/DZBGZ',
        name: 'DZBGZ',
        component: DZBGZ,
        props:{code:"9999",},
      },
      {
        path: '/NDZJ',
        name: 'NDZJ',
        component: NDZJ,
        props:{code:"9999",},
      },
      {
        path: '/YGSZBG',
        name: 'YGSZBG',
        component: YGSZBG,
        props:{code:"9999",},
      },
      {
        path: '/GZTYFA',
        name: 'GZTYFA',
        component: GZTYFA,
        props:{code:"9999",},
      },
      {
        path: '/BXLP',
        name: 'BXLP',
        component: BXLP,
        props:{code:"1209",},
      },
      {
        path: '/FCWZKC',
        name: 'FCWZKC',
        component: FCWZKC,
        props:{code:"9999",},
      },
      {
        path: '/GHJGMXB',
        name: 'GHJGMXB',
        component: GHJGMXB,
        props:{code:"1207",},
      },
      {
        path: '/GSWZKC',
        name: 'GSWZKC',
        component: GSWZKC,
        props:{code:"9999",},
      },
      {
        path: '/QNYSJH',
        name: 'QNYSJH',
        component: QNYSJH,
        props:{code:"1208",},
      },
      {
        path: '/ZTBYW',
        name: 'ZTBYW',
        component: ZTBYW,
        props:{code:"1210",},
      },
      {
        path: '/CKGL',
        name: 'CKGL',
        component: CKGL,
        props:{code:"1204",},
      },
      {
        path: '/ContractManage',
        name: 'ContractManage',
        component: ContractManage,
        props:{code:"1201",},
      },
      {
        path: '/KCGL',
        name: 'KCGL',
        component: KCGL,
        props:{code:"1206",},
      },
      {
        path: '/GYSGL',
        name: 'GYSGL',
        component: GYSGL,
        props:{code:"1202",},
      },
      {
        path: '/WZHPFL',
        name: 'WZHPFL',
        component: WZHPFL,
        props:{code:"1203",},
      },
      {
        path: '/WXGL',
        name: 'WXGL',
        component: WXGL,
        props:{code:"1205",},
      },


      {
        path: '/XTPTZH',
        name: 'XTPTZH',
        component: XTPTZH,
        props:{code:"13",},
      },
      {
        path: '/KHX',
        name: 'KHX',
        component: KHX,
        props:{code:"9999",},
      },
      {
        path: '/KHXM',
        name: 'KHXM',
        component: KHXM,
        props:{code:"9999",},
      },
      {
        path: '/KHGL',
        name: 'KHGL',
        component: KHGL,
        props:{code:"9999",},
      },
      {
        path: '/KHLB',
        name: 'KHLB',
        component: KHLB,
        props:{code:"9999",},
      },
      {
        path: '/YZFA',
        name: 'YZFA',
        component: YZFA,
        props:{code:"9999",},
      },
      {
        path: '/SCJL',
        name: 'SCJL',
        component: SCJL,
        props:{code:"9999",},
      },
      {
        path: '/GZZJJGZJH',
        name: 'GZZJJGZJH',
        component: GZZJJGZJH,
        props:{code:"9999",},
      },
      {
        path: '/QSMZQGLTZ',
        name: 'QSMZQGLTZ',
        component: QSMZQGLTZ,
        props:{code:"9999",},
      },

      {
        path: '/HQAQGL',
        name: 'HQAQGL',
        component: HQAQGL,
        props:{code:"14",},
      },
      {
        path: '/SCanteen',
        name: 'SCanteen',
        component: SCanteen,
        props:{code:"9999",},
      },
      {
        path: '/CLGL',
        name: 'CLGL',
        component: CLGL,
        props:{code:"9999",},
      },
      {
        path: '/JYBXJL',
        name: 'JYBXJL',
        component: JYBXJL,
        props:{code:"9999",},
      },
      {
        path: '/YZSQSP',
        name: 'YZSQSP',
        component: YZSQSP,
        props:{code:"9999",},
      },
      {
        path: '/FYZCGL',
        name: 'FYZCGL',
        component: FYZCGL,
        props:{code:"9999",},
      },
      
      

      {
        path: '/ZLKFL',
        name: 'ZLKFL',
        component: ZLKFL,
        props:{code:"0401",},
      },
      {
        path: '/ZLKGL',
        name: 'ZLKGL',
        component: ZLKGL,
        props:{code:"0402",},
      },
      {
        path: '/ZLKQXGL',
        name: 'ZLKQXGL',
        component: ZLKQXGL,
        props:{code:"0403",},
      },
      
      {
        path: '/StoreManage',
        name: 'StoreManage',
        component: StoreManage,
        props:{code:"1501",},
      },
      {
        path: '/RackManage',
        name: 'RackManage',
        component: RackManage,
        props:{code:"1502",},
      },
      {
        path: '/GoodsManage',
        name: 'GoodsManage',
        component: GoodsManage,
        props:{code:"1503",},
      },
      
      {
        path: '/MatterManage',
        name: 'MatterManage',
        component: MatterManage,
        props:{code:"1504",},
      },
      {
        path: '/InOutManage',
        name: 'InOutManage',
        component: InOutManage,
        props:{code:"1505",},
      },
      {
        path: '/LightManage',
        name: 'LightManage',
        component: LightManage,
        props:{code:"1506",},
      },
      {
        path: '/VideoManage',
        name: 'VideoManage',
        component: VideoManage,
        props:{code:"1507",},
      },
      
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var  xjlx=localStorage.getItem("fd_xjlx")
  if(xjlx){
    store.commit('setCheckType', xjlx);
  }else{
    if(to.name=='CheckTaskManage' && store.state.checkType=='0'){
      router.replace("/XJGL")
    }
  }
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router
