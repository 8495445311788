<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">待领料的领料单</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="zt" placeholder="选择状态"  filterable  clearable class="iw">
                        <el-option label="待审核" value="1" ></el-option>
                        <el-option label="待审拒绝" value="2" ></el-option>
                        <el-option label="可取件" value="3" ></el-option>
                        <el-option label="取件完成" value="4" ></el-option>
                        <el-option label="已取消" value="5" ></el-option>
                </el-select>
                <el-select v-model="ckid" placeholder="选择仓库"  filterable  clearable class="iw">
                        <el-option v-for="item in ckList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-input v-model="xm" placeholder="请输入申请人姓名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Status" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.Status==1" style="color:#909399">待审核</span>
                            <span v-else-if="scope.row.Status==2" style="color:#E6A23C">待审拒绝</span>
                            <span v-else-if="scope.row.Status==3" style="color:#409EFF">可取件</span>
                            <span v-else-if="scope.row.Status==4" style="color:#67C23A">取件完成</span>
                            <span v-else-if="scope.row.Status==5" style="color:#F56C6C">已取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CreatorName" label="申请人"></el-table-column>
                    <el-table-column prop="WareHouseName" label="领料仓库"></el-table-column>
                    <el-table-column prop="orderStr" label="领料清单"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column prop="CreateTime" label="申请时间"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="OutLogPost(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.Status!=1 && scope.row.Status!=2">查看领料记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="领料记录查看" :visible.sync="logModal" width="1200px" :close-on-click-modal="false" top="30px">
            <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh" > 
                <el-table-column prop="WAREHOUSENAME" label="仓库"></el-table-column>
                <el-table-column prop="GoodName" label="货名"></el-table-column>
                <el-table-column prop="GoodCount" label="出库数量"></el-table-column>
                <el-table-column prop="SAVECODE" label="出库货格"></el-table-column>
                <el-table-column prop="Target" label="出库去向"></el-table-column>
                <el-table-column prop="BZ" label="备注"></el-table-column>
            </el-table>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    

    var count= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };


    return {
        ckList:[],
        dialogMaxHeigh:0,

        zt:"",
        canDo:true,
        showPagination:false,
        xm:"",
        ckid:"",
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        logModal:false,
        logList:[],
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
    this.getCkList()
  },
  methods: {
    getCkList(){
        this.$storeAxios.post("WareHouse/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ckList=response.data.DATA
                } 
            })
    },
    storeModalClose(){
        this.nowOrder=null  
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            userName:this.xm,
            status:this.zt,
            wareHouseSnowID:this.ckid,
        }
        this.$storeAxios.post("GoodList/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nl=[]
                var list =response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.orderStr=""
                    for(var j=0;j<d.GoodListDetailList.length;j++){
                        var o=d.GoodListDetailList[j]
                        if(j==0){
                            d.orderStr=o.GoodName+"×"+o.NeedCount
                        }else{
                            d.orderStr+="、"+o.GoodName+"×"+o.NeedCount
                        }
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    OutLogPost(item){
        this.showLoading("请稍后")
        var params={
            GoodListSnowID:item.SnowID,
            status:"4",
        }
        this.logList=[]
        this.$storeAxios.post("GoodIn/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.logList=response.data.DATA
                    this.logModal=true
                }
            })
            .catch((error) => {
                this.hideLoading()
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.goodsOutTH{height:40px;overflow:hidden;border-bottom: 2px solid #333333;}
.goodsOutItemP{width: 100px;font-size: 14px;line-height: 40px;padding: 0 12px;float:left;}
.goodsSourceListOut{overflow: hidden;padding: 2px 10px;background-color: #F8F8F8;border-radius: 3px;line-height: 40px;height:40px;}
.goodsSourceList{overflow: hidden;border-bottom: 1px solid #ADADAD;}
.goodsSourceList:last-of-type{border-bottom: 0!important;}
.goodsSourceList .positionInfoStr{float:left;font-size: 12px;line-height: 30px;}
.goodsSourceList .goodsCheckCount{float:left;margin-left: 10px;}
.goodsOutTH .goodsOutItemP{font-weight: bold;}
.checkError{font-size: 12px;margin: 5px 0;color: #F56C6C;}
.goodsPositionLoading,.goodsEmpty{height: 40px;text-align: center;color: #ADADAD;font-size: 12px}
.goodsOutItem{overflow: hidden;border-bottom: 1px solid #ADADAD;}
</style>