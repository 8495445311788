<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">货架管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <!-- <el-input v-model="name" placeholder="请输入货架名称" class="iw" ></el-input> -->
                <el-input v-model="code" placeholder="请输入货架代码" class="iw" ></el-input>
                <el-select v-model="ck" placeholder="选择仓库"  filterable  clearable class="iw">
                        <el-option v-for="item in ckList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Index" label="排序"></el-table-column>
                    <el-table-column prop="Code" label="货架编号"></el-table-column>
                    <el-table-column prop="WareHouseName" label="所在仓库"></el-table-column>
                    <el-table-column prop="Size" label="容量"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false" top="50px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                
                <el-form-item label="仓库" prop="wareHouseSnowID">
                    <el-select v-model="postInfo.wareHouseSnowID" placeholder="请选择仓库"  filterable clearable style="width:100%;">
                        <el-option v-for="item in ckList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="货架代码" prop="code" style="width:50%;float:left;">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="index" style="width:50%;float:left;">
                    <el-input v-model="postInfo.index" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="设置行数" prop="rowCount" style="width:33%;float:left;">
                    <el-input-number v-model="postInfo.rowCount" controls-position="right" @change="createInfoArra(false)" :precision="0" :step="1" :min="1" :max="16"></el-input-number>
                </el-form-item>
                <el-form-item label="设置列数" prop="columnCount" style="width:33%;float:left;">
                    <el-input-number v-model="postInfo.columnCount" controls-position="right" @change="createInfoArra(false)" :precision="0" :step="1" :min="1" :max="16"></el-input-number>
                </el-form-item>
                <el-form-item label="设置货格数"  prop="boxCount" style="width:33%;float:left;">
                    <el-input-number v-model="postInfo.boxCount" controls-position="right" @change="createInfoArra(true)" :precision="0" :step="1" :min="1" :max="8"></el-input-number>
                </el-form-item>
                <div class="setBoxMsg">提示：通过修改行、列和货格数量调整货架结构，其中行和列为固定数量，每列的格口数量可以在下方单独修改。</div>
                <el-form-item label="货架结构">
                    <div class="rackOutBox">
                        <div v-for="(rows,rowindex) in infoArray" :key="rowindex" style="line-height:37px;">
                            <div class="rowBox" :class="rowindex == (infoArray.length -1)?'lastRow':''">
                                <div class="colBox" v-for="(cols,colsindex) in rows" :key="colsindex" >
                                    <el-input-number v-model="cols.value" controls-position="right" :precision="0" :step="1" :min="1" :max="8" size="mini" style="width:80px;margin-top:4px;"></el-input-number>
                                </div>
                            </div>
                            <br>
                        </div>
                        
                        <div style="width:100%;height:20px;float:left;"></div>
                    </div>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "RackManage",
  data() {
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入货架代码"));
      } else {
        callback();
      }
    };
    var areaSnowID = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择仓库区域"));
      } else {
        callback();
      }
    };
    var index= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入排序'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('排序只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var rowCount= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入行数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('行数只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };
    var columnCount= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入列数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('列数只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };
    var boxCount= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入货格数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('货格数只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };


    return {
        canDo:true,
        showPagination:false,
        name:"",
        code:"",
        ck:"",
        ckList:[],

        infoArray:[],


        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,



        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            // name: [{ validator: name, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            areaSnowID: [{ validator: areaSnowID, trigger: "blur" }],
            index: [{ validator: index, trigger: "blur" }],
            rowCount: [{ validator: rowCount, trigger: "blur" }],
            columnCount: [{ validator: columnCount, trigger: "blur" }],
            boxCount: [{ validator: boxCount, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getCkList()
    this.getPostList()
  },
  methods: {
    getCkList(){
        this.$storeAxios.post("WareHouse/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ckList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            // name:this.name,
            code:this.code,
            wareHouseSnowID:this.ck,
        }
        this.$storeAxios.post("Shelving/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nl=[]
                var list=response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.Size=""
                    
                    var info=JSON.parse(d.Info)
                    var h=info.length
                    var l=info[0].length
                    var g=0
                    for(var j=0;j<info.length;j++){
                        for(var k=0;k<info[j].length;k++){
                            g+=info[j][k]
                        }
                    }
                    d.Size=h+"行"+l+"列,共"+g+"个货格"
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    createInfoArra(flag){
        var nl=[]
        for(var i=0;i<this.postInfo.rowCount;i++){
            var lie=[]
            for(var j=0;j<this.postInfo.columnCount;j++){
                var value=0
                if(flag){
                    value=this.postInfo.boxCount
                }else{
                    if(this.infoArray.length>0 && this.infoArray[i]!= undefined && this.infoArray[i][j]!= undefined){
                        value= this.infoArray[i][j].value
                    }else{
                        value=this.postInfo.boxCount
                    }
                }
                
                lie.push({value:value})
            }
            nl.push(lie)    
        }
        this.infoArray=nl
    },
    addPost(){
        this.postInfo={
            code:"",
            wareHouseSnowID:"",
            index:"",
            rowCount:6,
            columnCount:10,
            boxCount:4,
        }
        this.createInfoArra(true)
        this.doTypeStr="添加货架"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var infos=[]
        for(var i=0;i<this.infoArray.length;i++){
            var row=[]
            for(var j=0;j<this.infoArray[i].length;j++){
                row.push(this.infoArray[i][j].value) 
            }
            infos.push(row)
        }
        var params={
            wareHouseSnowID:this.postInfo.wareHouseSnowID,
            code:this.postInfo.code,
            index:this.postInfo.index,
            Info:infos,  
        }

        this.$storeAxios.post('Shelving/InsertData', params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        var info=JSON.parse(item.Info);
        this.postInfo={
            snowID:item.SnowID,
            code:item.Code,
            wareHouseSnowID:item.WareHouseSnowID,
            index:item.Index,
            rowCount:info.length,
            columnCount:info[0].length,
            boxCount:parseInt(info[0][0]),
        }
       
        var nl=[]
        for(var i=0;i<info.length;i++){
            var lie=[]
            for(var j=0;j<info[i].length;j++){
                lie.push({value:parseInt(info[i][j])})
            }
            nl.push(lie)
        }
        this.infoArray = nl


        this.doTypeStr="修改货架"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var infos=[]
        for(var i=0;i<this.infoArray.length;i++){
            var row=[]
            for(var j=0;j<this.infoArray[i].length;j++){
                row.push(this.infoArray[i][j].value) 
            }
            infos.push(row)
        }
        var params={
            snowID:this.postInfo.snowID,
            wareHouseSnowID:this.postInfo.wareHouseSnowID,
            code:this.postInfo.code,
            index:this.postInfo.index,
            Info: JSON.stringify(infos),  
        }
        this.$storeAxios.post("Shelving/UpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除该货架？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$storeAxios.post("Shelving/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
            });
    },
    showPost(item){
        //
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.setBoxMsg{font-size:12px;color:#E6A23C;text-indent: 100px;margin:20px 0;}
.rackOutBox{overflow: auto;width: 100%;max-height: 400px;}
.rackOutBox .rowBox{border-top: 1px solid #ADADAD;border-left: 1px solid #ADADAD;float:left;overflow: hidden;white-space: nowrap;font-size:0px;line-height:28px;}
.lastRow{border-bottom: 1px solid #ADADAD;}
.rackOutBox .rowBox .colBox{border-right: 1px solid #ADADAD;padding:0 6px;display:inline-block;}
</style>