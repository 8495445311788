import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initRouter:"",
    openPage:"",

    token:"",
    admin:"",
    endTime:"",
    guid:"",
    name:"",
    loginName:"",
    password:"",
    departments:[],
    funDisable:null,
    postList: [], // 用户的岗位数组

    checkType:"0",

    storeToken:"",
  },
  getters: {
    getInitRouter: state => state.initRouter,
    getOpenPage: state => state.openPage,

    getToken: state => state.token,
    getAdmin: state => state.admin,
    getEndTime: state => state.endTime,
    getGuid: state => state.guid,
    getName: state => state.name,
    getLoginName: state => state.loginName,
    getPassword: state => state.password,
    getDepartments: state => state.departments,
    getFunDisable: state => state.funDisable,

    getCheckType: state => state.checkType,
    getPostList: state => state.postList,
    getStoreToken: state => state.storeToken,

  },
  mutations: {
    setInitRouter(state, data) {
      state.initRouter = data
    },
    setOpenPage(state, data) {
      state.openPage = data
    },


    setToken(state, data) {
        state.token = data
    },
    setStoreToken(state, data) {
      state.storeToken = data
  },
    setAdmin(state, data) {
      state.admin = data
    },
    setEndTime(state, data) {
      state.endTime = data
    },
    setGuid(state, data) {
      state.guid = data
    },
    setName(state, data) {
      state.name = data
    },
    setLoginName(state, data) {
      state.loginName = data
    },
    setPassword(state, data) {
      state.password = data
    },
    setDepartments(state, data) {
      state.departments = data
    },
    setFunDisable(state, data) {
      state.funDisable = data
    },

    setCheckType(state, data) {
      state.checkType = data
    },
    setPostList(state,data){
      state.postList = data
    }
  },
  actions: {
    setInitRouter({commit, state}, data) {
      commit('setInitRouter', data)
    },
    setOpenPage({commit, state}, data) {
      commit('setOpenPage', data)
    },

    setToken({commit, state}, data) {
      commit('setToken', data)
    },
    setStoreToken({commit, state}, data) {
      commit('setStoreToken', data)
    },
    setAdmin({commit, state}, data) {
      commit('setAdmin', data)
    },
    setEndTime({commit, state}, data) {
      commit('setEndTime', data)
    },
    setGuid({commit, state}, data) {
      commit('setGuid', data)
    },
    setName({commit, state}, data) {
      commit('setName', data)
    },
    setLoginName({commit, state}, data) {
      commit('setLoginName', data)
    },
    setPassword({commit, state}, data) {
      commit('setPassword', data)
    },
    setDepartments({commit, state}, data) {
      commit('setDepartments', data)
    },
    setFunDisable({commit, state}, data) {
      commit('setFunDisable', data)
    },
    setCheckType({commit, state}, data) {
      commit('setCheckType', data)
    },
  },
  modules: {
  }
})
